import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthStore} from "../../../../home-layout/common/store/auth-store.service";
import {ROUTE} from "../../../../home-layout/common/constant/routing.constant";
import {TokenService} from "../token.service";
import {SupportUserService} from "../../../support-layout/common/service/support-user.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {

    constructor(private router: Router,
                private authStore: AuthStore,
                private tokenService: TokenService,
                private supportUserService: SupportUserService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
        this.tokenService.setAccessToken(route.queryParamMap.get(ROUTE.MOBILE_TOKEN));
        const support = route.queryParamMap.get(ROUTE.SUPPORT);
        if (support) {
            this.supportUserService.saveSupportLoginFlag(support === 'true');
        }
        if (this.authStore.isLoggedIn()) {
            if (route.data['name'] && route.data['name'] === ROUTE.AUTH) {
                return this.router.createUrlTree([ROUTE.SLASH + ROUTE.DASHBOARD]);
            }
            return true;
        } else if (route.data['name'] && route.data['name'] === ROUTE.AUTH) {
            return true;
        } else {
            return this.gotoLoginPage(state.url);
        }
    }

    private gotoLoginPage(redirectUrl: string): UrlTree {
        return this.router.createUrlTree(
            [ROUTE.SLASH + ROUTE.AUTH + ROUTE.SLASH + ROUTE.LOGIN],
            {queryParams: {redirectUrl}}
        );
    }
}
