import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {EdgeFirebaseApiService} from "../../notification/common/service/edge-firebase-api.service";
import {environment} from "../../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class SubDomainStore {

    protected default_short_logo = 'assets/branding/logo/default_short_logo.svg';
    protected default_long_logo = 'assets/branding/logo/default_long_logo.png';

    private _subdomain = new BehaviorSubject<string>('');
    public subdomain: Observable<string>;

    private _baseUrl = new BehaviorSubject<string>('');
    public baseUrl: Observable<string>;

    private _standoutUrl = new BehaviorSubject<string>('');
    public standoutUrl: Observable<string>;

    private _hireOSUrl = new BehaviorSubject<string>('');
    public hireOSUrl: Observable<string>;

    private _organizaUrl = new BehaviorSubject<string>('');
    public organizaUrl: Observable<string>;

    private _loginLogoUrl = new BehaviorSubject<string>('');
    public loginLogoUrl: Observable<string>;

    private _shortLogoUrl = new BehaviorSubject<string>('');
    public shortLogoUrl: Observable<string>;

    private _longLogoUrl = new BehaviorSubject<string>('');
    public longLogoUrl: Observable<string>;

    private _apiUrl = new BehaviorSubject<string>('');
    public apiUrl: Observable<string>;

    constructor(private edgeFirebaseApiService: EdgeFirebaseApiService) {
        this.initialize();
        this.subdomain = this._subdomain.asObservable();
        this.baseUrl = this._baseUrl.asObservable();
        this.standoutUrl = this._standoutUrl.asObservable();
        this.hireOSUrl = this._hireOSUrl.asObservable();
        this.organizaUrl = this._organizaUrl.asObservable();
        this.loginLogoUrl = this._loginLogoUrl.asObservable();
        this.shortLogoUrl = this._shortLogoUrl.asObservable();
        this.longLogoUrl = this._longLogoUrl.asObservable();
        this.apiUrl = this._apiUrl.asObservable();
    }

    private initialize(): void {
        const subdomain = window.location.hostname.split('.')[0];
        this.publishSubdomain(subdomain);
        this.publishBaseUrl(this.getBaseUrl());
        this.setApiUrl(subdomain);
        this.setStandoutUrl(subdomain);
        this.setHireOSUrl(subdomain);
        this.setOrganizaUrl(subdomain);
        this.setLoginLogoUrl(subdomain);
        this.setShortLogoUrl(subdomain);
        this.setLongLogoUrl(subdomain);
    }

    private getBaseUrl(): string {
        return `${window.location.protocol}//${window.location.host}/`;
    }

    private publishSubdomain(subdomain: string): void {
        this._subdomain.next(subdomain);
    }

    private publishBaseUrl(baseUrl: string): void {
        this._baseUrl.next(baseUrl);
    }

    private publishApiUrl(apiUrl: string): void {
        this._apiUrl.next(apiUrl);
    }

    private publishStandoutUrl(standoutUrl: string): void {
        this._standoutUrl.next(standoutUrl);
    }

    private publishHireOSUrl(hireOSUrl: string): void {
        this._hireOSUrl.next(hireOSUrl);
    }

    private publishOrganizaUrl(organizaUrl: string): void {
        this._organizaUrl.next(organizaUrl);
    }

    private publishLoginLogoUrl(loginLogoUrl: string): void {
        this._loginLogoUrl.next(loginLogoUrl);
    }

    private publishShortLogoUrl(shortLogoUrl: string): void {
        this._shortLogoUrl.next(shortLogoUrl);
    }

    private publishLongLogoUrl(longLogoUrl: string): void {
        this._longLogoUrl.next(longLogoUrl);
    }

    private setApiUrl(subdomain: string): void {
        this.edgeFirebaseApiService.getApiUrl(subdomain).subscribe(apiUrl => {
            if (apiUrl) {
                this.publishApiUrl(apiUrl as string);
            } else {
                this.setApiUrl('default');
            }
        });
    }

    private setStandoutUrl(subdomain: string): void {
        this.publishStandoutUrl(environment.standoutBaseAppUrl);
        this.edgeFirebaseApiService.getStandoutUrl(subdomain).subscribe(standoutUrl => {
            let standoutUrlStr = standoutUrl as string;
            console.log('setStandoutUrl standoutUrlStr:', standoutUrlStr)
            standoutUrlStr = standoutUrlStr?.length > 0 ? standoutUrlStr : environment.standoutBaseAppUrl;
            this.publishStandoutUrl(standoutUrlStr);
        });
    }

    private setHireOSUrl(subdomain: string): void {
        this.publishHireOSUrl(environment.hireOsBaseAppUrl);
        this.edgeFirebaseApiService.getHireOSUrl(subdomain).subscribe(hireOSUrl => {
            let hireOSUrlStr = hireOSUrl as string;
            console.log('setHireOSUrl hireOSUrlStr:', hireOSUrlStr)
            hireOSUrlStr = hireOSUrlStr?.length > 0 ? hireOSUrlStr : environment.hireOsBaseAppUrl;
            this.publishHireOSUrl(hireOSUrlStr);
        });
    }

    private setOrganizaUrl(subdomain: string): void {
        this.publishOrganizaUrl(environment.organizaBaseAppUrl);
        this.edgeFirebaseApiService.getOrganizaUrl(subdomain).subscribe(organizaUrl => {
            let organizaUrlStr = organizaUrl as string;
            console.log('setOrganizaUrl organizaUrlStr:', organizaUrlStr)
            organizaUrlStr = organizaUrlStr?.length > 0 ? organizaUrlStr : environment.organizaBaseAppUrl;
            this.publishOrganizaUrl(organizaUrlStr);
        });
    }

    private setLoginLogoUrl(subdomain: string): void {
        // NOTE: commented below as it is showing default talenlio logo before subdomain logo
        // this.publishLoginLogoUrl(this.default_long_logo);
        this.edgeFirebaseApiService.getLoginLogoUrl(subdomain).subscribe(loginLogoUrl => {
            let loginLogoUrlStr = loginLogoUrl as string;
            console.log('setLoginLogoUrl loginLogoUrlStr:', loginLogoUrlStr)
            loginLogoUrlStr = loginLogoUrlStr?.length > 0 ? loginLogoUrlStr : this.default_long_logo;
            this.publishLoginLogoUrl(loginLogoUrlStr);
        });
    }

    private setShortLogoUrl(subdomain: string): void {
        this.publishShortLogoUrl(this.default_short_logo);
        this.edgeFirebaseApiService.getShortLogoUrl(subdomain).subscribe(shortLogoUrl => {
            let shortLogoUrlStr = shortLogoUrl as string;
            console.log('setShortLogoUrl shortLogoUrlStr:', shortLogoUrlStr)
            shortLogoUrlStr = shortLogoUrlStr?.length > 0 ? shortLogoUrlStr : this.default_short_logo;
            this.publishShortLogoUrl(shortLogoUrlStr);
        });
    }

    private setLongLogoUrl(subdomain: string): void {
        // NOTE: commented below as it is showing default talenlio logo before subdomain logo
        // this.publishLongLogoUrl(this.default_long_logo);
        this.edgeFirebaseApiService.getLongLogoUrl(subdomain).subscribe(longLogoUrl => {
            let longLogoUrlStr = longLogoUrl as string;
            console.log('setLongLogoUrl longLogoUrlStr:', longLogoUrlStr)
            longLogoUrlStr = longLogoUrlStr?.length > 0 ? longLogoUrlStr : this.default_long_logo;
            this.publishLongLogoUrl(longLogoUrlStr);
        });
    }
}
